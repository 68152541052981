import request from '@/utils/request'

// 患者管理 - 获取用户列表
export function getMemberList(params) {
    return request({
        url: '/admin/memberList',
        method: 'GET',
        params
    })
}

// 患者管理 - 获取用户详情
export function getMemberInfo(id) {
    return request({
        url: `/admin/memberInfo/${id}`,
        method: 'GET',
    })
}
// 患者管理 - 删除用户
export function delMember(id) {
    return request({
        url: `/admin/delMember/${id}`,
        method: 'DELETE'
    })
}

// 患者管理 - 获取就诊人列表
export function getPatientList(id) {
    return request({
        url: `/admin/patientList/${id}`,
        method: 'GET',
    })
}


// 患者管理 - 删除就诊人
export function delPatient(pat_id) {
    return request({
        url: `/admin/delPatient/${pat_id}`,
        method: 'DELETE'
    })
}

// 患者管理 - 修改患者信息
//admin/updateMember/{id}
export function postUpdateMember(data) {
    return request({
        url: `/admin/updateMember/${data.id}`,
        method: 'POST',
        data
    })
}

// 患者管理 - 发放会员权益
//admin/sendVipInterest/{id}
export function sendVipInterest(id) {
    return request({
        url: `/admin/sendVipInterest/${id}`,
        method: 'POST',
    })
}
// 导入患者档案
export function postPatientImportPat(data) {
  return request({
    url: `/admin/patient/importPat`,
    method: 'POST',
    data
  })
}

// 随访记录
// #20104039admin/patient/follow/{member}
export function getPatientFollow(params) {
  return request({
    url: `admin/patient/follow/${params.id}`,
    method: 'GET',
    params,
  })
}
