<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			title=""
			:visible.sync="patientManagementDialogsVisible"
			width="640px"
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">编辑患者信息</span>
			<el-form :model="infoData" :rules="rules" size="mini" :inline="true" label-position="top" ref="ruleForm">
				<el-form-item label="姓名" prop="realname" class="from-item-second">
					<el-input v-model="infoData.realname" autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="昵称" prop="nickname" class="from-item-second">
					<el-input v-model="infoData.nickname" autocomplete="off" disabled></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="mobile" :maxlength="11" class="from-item-second">
					<el-input v-model.number.trim="infoData.mobile" autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="性别" class="from-item-second" prop="gender">
					<el-radio-group v-model="infoData.gender">
						<el-radio :label="1">男</el-radio>
						<el-radio :label="2">女</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="来源" class="from-item-second" prop="source">
					<el-input v-model="infoData.source" autocomplete="off" disabled></el-input>
				</el-form-item>
				<!--<el-form-item label="终端" class="from-item-second" prop="terminal">-->
				<!--	<el-input v-model="infoData.terminal" autocomplete="off" disabled></el-input>-->
				<!--</el-form-item>-->
				<el-form-item label="激活状态" class="from-item-second" prop="active_status">
					<el-input v-model="active_status[infoData.active_status]" autocomplete="off" disabled></el-input>
				</el-form-item>
				<el-form-item label="活跃时间" class="from-item-second" prop="last_time">
					<el-input v-model="infoData.last_time" autocomplete="off" disabled></el-input>
				</el-form-item>
				<el-form-item label="省市区" class="from-item-second" prop="ProvinceList" v-if="infoData.selectedOptions.length>0">
					<el-cascader :options="options"
											 v-model="infoData.selectedOptions"
											 placeholder="省市区"
											 @change="handleChange"
											 filterable>
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" class="from-item-second" prop="address">
					<el-input v-model="infoData.address" autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="生日" class="from-item-second" prop="birthday">
					<el-date-picker
						v-model="infoData.birthday"
						type="date"
						placeholder="选择日期"
						:default-value="new Date('1999-09-09')"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="原始微信" class="from-item-second" prop="wx">
					<el-input v-model="infoData.mobile" autocomplete="off" disabled></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="small" @click="handleCancel">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {postUpdateMember, getMemberInfo} from '@/api/patientManagement';
import { regionDataPlus , CodeToText } from 'element-china-area-data'

export default {
  name: "patientManagementDialogs",
	props: {
    isActiveDialogs: {
      type: Boolean,
      default: false
		},
    patientManagementDialogsVisible: {
      type: Boolean,
			default: false
		},
    info: {
      type: Object,
      required: true,
    },
    handleClearInfo: {
      type: Function,
      required: true
    },
    getList: {
      type: Function,
      required: true
		},
	},
	async created () {
    await this._patientDetail()
  },
  filters: {
    genderFormat(v) {
      if ( v === 0) {
        return '未知'
      } else if ( v === 1 ) {
        return '男'
      } else {
        return '女'
      }
    }
  },
  data() {
    const validateOrg = (rule, value, callback) => {
      if (this.infoData.selectedOptions.length && this.infoData.selectedOptions[0].length === 0) {
        callback(new Error('请选择省市区'))
			}
      if (!this.infoData.selectedOptions.length) {
        callback(new Error('请选择省市区'))
      } else {
        callback()
      }
    }
    
    const checkGender = (rule, value, callback) => {
      if (!value){
        callback(new Error('请选择性别'))
			} else {
        callback()
			}
		}
    
    let infoData = JSON.parse(JSON.stringify(this.info))
    infoData.mobile = Number(infoData.mobile)
    infoData.source_number = infoData.source
		infoData.source = infoData.source_name
		// infoData.address = ''
		// infoData.addr = ''
    infoData.selectedOptions = []
    return {
      listLoading: false,
      infoData: infoData,
      options: regionDataPlus,
      active_status: ['待激活', '激活'],
      source_name: [
        {
          value: '1',
          label: '患者场景'
        }, {
          value: '2',
          label: '慢病场景'
        }, {
          value: '4',
          label: '医生场景'
        }
			],
      rules: {
        realname: [
          { required: true, message: '请输输入真实姓名', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入手机号,必须是数字', trigger: 'blur' },
          { type: 'number', message: '请输入手机号,必须是数字', trigger: 'blur'}
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'blur' },
          { required: true, message: '请选择性别', validator: checkGender },
        ],
        ProvinceList: [
          { type: 'array' , required: true,  trigger: 'change' , validator: validateOrg}
        ],
        address: [
          { required: true, message: '输入详细地址', trigger: 'blur' }
        ],
      },
      patientDetail: {}
    }
	},
	methods: {
    _patientDetail() {
      this.listLoading = true
      getMemberInfo(this.info.id).then(response => {
        let data = response
        if (data.code === 200) {
          this.patientDetail = data.data
					this.infoData.selectedOptions.push(...[this.patientDetail.province,this.patientDetail.city,this.patientDetail.area])
					this.infoData = Object.assign(this.patientDetail, this.infoData)
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _postUpdateMember(data){
      this.listLoading = true
      postUpdateMember(data).then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        }
        this.getList()
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        })
        this.listLoading = false
      })
    },
    submitForm(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate((valid) => {
        if (valid) {
					if (this.infoData.source === this.infoData.source_name) {
					  this.infoData.source = this.infoData.source_number
					}
					this.infoData.mobile = this.infoData.mobile.toString()
          this._postUpdateMember(this.infoData)
					setTimeout(() => {
            this.handleConfirm()
					})
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    handleConfirm() {
      this.handleClearInfo()
      this.infoData = {}
      this.patientManagementDialogsVisible && this.$emit("update:patientManagementDialogsVisible", false);
      this.isActiveDialogs && this.$emit("update:isActiveDialogs", false);
		},
    handleCancel(e) {
      this.handleClearInfo()
      this.infoData = {}
      this.patientManagementDialogsVisible && this.$emit("update:patientManagementDialogsVisible", false);
      this.isActiveDialogs && this.$emit("update:isActiveDialogs", false);
    },
    handleChange (value) {
      console.log(value) // value值为区域码
      // 用CodeToText转换成中文
			this.infoData.province = value[0]
			this.infoData.city = value[1]
			this.infoData.area = value[2]
      // console.log(CodeToText[value[0]])
      // console.log(CodeToText[value[1]])
    }
	}
}
</script>

<style scoped lang="scss">
	.mgl-20{
		margin-left: 20px;
	}
	.mgr-20{
		margin-right: 20px;
	}
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body{
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer{
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0 ;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label{
		padding-bottom: 0;
	}
	/deep/ .el-form-item__content{
		width: 280px;
	}
	
	.from-item-second {
		margin-left: 0;
		margin-right: 20px;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content{
			width: 590px;
		}
	}
	
	/deep/ .el-cascader{
		width: 280px;
	}
	
	/deep/ .el-select{
		width: 280px !important;
	}
	
	/deep/ .el-date-editor{
		width: 280px !important;
	}
		
		/*select 宽度配置*/
	/deep/ .el-select{
		width: 85px;
	}
	
	
</style>
